/* Police d'écriture */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #34495e;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
