.navigation {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 100%;
  height: 50px;
  background-color: #2c3e50;
  filter: drop-shadow(2px 4px 6px #718093);
  position: fixed;
  z-index: 99999;
  top: 0;
  padding: 4px;
}

// Image logo
.logo {
  display: block;
  width: 200px;
  margin-left: 42%;
}

.hamburger {
  display: none;
}

.navigation ul li {
  list-style-type: none;
  padding-right: 10px;
}

.navigation ul {
  font-family: 'Quicksand', sans-serif;
  background-color: #2c3e50;
  height: 100vh;
  width: 25vw;
  margin-top: 50px;
  position: absolute;
}

.modeOuvert {
  //display :flex;
  //flex-direction: column;
  //justify-content: center;
  border: none;
  width: 2rem;
  height: 35px;
  border-radius: 10px;
  background-color: transparent;
  transform-origin: 1px;
  position: relative;
  padding: 0;
  margin: 5px 0 0 15px;
  cursor: pointer;

  .burger1 {
    width: 100%;
    height: 4px;
    border-radius: 30px;
    transform: rotate(45deg);
    background-color: white;
    position: absolute;
    top: 50%;
    transition: all 0.3s;
  }
  .burger2 {
    height: 4px;
    border-radius: 30px;
    transform: translateX(-100%);
    background-color: white;
    opacity: 0;
    transition: all 0.3s;
  }
  .burger3 {
    width: 100%;
    height: 4px;
    border-radius: 30px;
    transform: rotate(-45deg);
    background-color: white;
    position: absolute;
    top: 50%;
    transition: all 0.3s;
  }
}

.modeFerme {
  width: 2rem;
  border: none;
  height: 35px;
  border-radius: 10px;
  background-color: transparent;
  transform-origin: 1px;
  position: relative;
  padding: 0;
  margin: 5px 0 0 15px;
  cursor: pointer;

  .burger1 {
    width: 100%;
    height: 4px;
    border-radius: 30px;
    transform: rotate(0deg);
    background-color: white;
    transition: all 0.3s linear;
    position: absolute;
    top: 12%;
  }
  .burger2 {
    width: 100%;
    height: 4px;
    transform: translateX(0);
    background-color: white;
    border-radius: 30px;
    opacity: 1;
    transition: all 0.3s linear;
    position: absolute;
    top: 46%;
  }
  .burger3 {
    width: 100%;
    height: 4px;
    border-radius: 30px;
    transform: rotate(0deg);
    background-color: white;
    transition: all 0.3s linear;
    position: absolute;
    bottom: 12%;
  }
}
.lienNav {
  border: 1px solid transparent;
  text-decoration: none;
  color: white;
  font-size: 34px;
  padding-bottom: 5vh;
}

.lienNav:hover {
  text-decoration: 5px underline #eb3e44;
}

.navUlOpen {
  left: -900px;
  display: flex;
}
.navUlClose {
  left: 0px;
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 0;
}

.navlinkPosition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// Boutons déconnexion
.deconnexion {
  font-family: 'Quicksand', sans-serif;
  font-size: large;
  margin-left: 15px;
  margin-bottom: 20px;
  width: 150px;
  height: 25px;
  border-radius: 30px;
  border: none;
  background-color: #f1f1f1f1;
  cursor: pointer;
  text-decoration: none;
  color: black;
  text-align: center;
}

.deconnexion:hover {
  background-color: #eb3e44;
  color: white;
}

@media screen and (max-width: 700px) {
  .lienNav {
    border: 1px solid transparent;
    text-decoration: none;
    color: white;
    font-size: 4vw;
    padding-bottom: 5vh;
  }
  .deconnexion {
    width: 105px;
    margin-left: -10px;
    font-size: medium;
  }
  .logo {
    margin-left: 15%;
  }
}
