/* Css de l'espace utilisateur */

/* titre H1 */

.espaceUtilisateurTitre {
  text-align: center;
  margin-top: 60px;
  color: white;
  text-decoration: underline;
}

/* Button de redirection de page dans l'espace */

.lienButton {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

/* redirections vers favoris */

.bFavoris {
  font-family: 'Quicksand', sans-serif;
  font-size: large;
  width: 100px;
  height: 20px;
  text-align: center;
  margin-left: 15px;
  border: solid 2px #f1f1f1f1;
  padding: 10px;
  border-radius: 30px;
  text-decoration: none;
  color: white;
  margin-top: -20px;
}

.bFavoris:hover {
  background-color: #f1f1f1f1;
  text-align: center;
  color: black;
  font-size: large;
}

/* redirection accueil */

.bAccueil {
  font-family: 'Quicksand', sans-serif;
  font-size: large;
  width: 100px;
  height: 20px;
  text-align: center;
  margin-left: 15px;
  border: solid 2px #f1f1f1f1;
  padding: 10px;
  border-radius: 30px;
  text-decoration: none;
  color: white;
  margin-top: -20px;
}

.bAccueil:hover {
  background-color: #f1f1f1f1;
  text-align: center;
  color: black;
  font-size: large;
}

/* redirection vers mon indice */

.bIndice {
  font-family: 'Quicksand', sans-serif;
  font-size: large;
  width: 100px;
  height: 20px;
  text-align: center;
  margin-left: 15px;
  border: solid 2px #f1f1f1f1;
  padding: 10px;
  border-radius: 30px;
  text-decoration: none;
  color: white;
  margin-top: -20px;
}

.bIndice:hover {
  background-color: #f1f1f1f1;
  text-align: center;
  color: black;
  font-size: large;
}

/* redirection vers modifier le mot de passe  */

.bMdp {
  font-family: 'Quicksand', sans-serif;
  font-size: large;
  width: 250px;
  height: 20px;
  text-align: center;
  margin-left: 15px;
  border: solid 2px #f1f1f1f1;
  padding: 10px;
  border-radius: 30px;
  text-decoration: none;
  color: white;
  margin-top: -20px;
}

.bMdp:hover {
  background-color: #f1f1f1f1;
  text-align: center;
  color: black;
  font-size: large;
}

/* -------- partie responsive de la page Utilisateur -------- */

@media screen and (max-width: 700px) {
  .lienButton {
    flex-direction: column;
  }

  .bAccueil {
    margin-bottom: 30px;
  }

  .bFavoris {
    margin-bottom: 30px;
  }

  .bIndice {
    margin-bottom: 30px;
  }
}
