.listeLieuxcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.pageTitle {
  color: white;
  font-size: 60px;
  text-align: center;
}

.searchInput {
  border-radius: 10px;
  height: 3rem;
  width: 100%;
  font-size: 2em;
  min-height: 50px;
}
.flexTitles {
  display: flex;
  justify-content: space-around;
  width: 100%;
  color: white;
  margin-top: 20px;
  background: rgba(124, 124, 124, 0.3);

}

.thmTitle{
  font-size: 40px;
}

.containerMap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 2px solid white;
}

.flexItem {
  height: 12vh;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  border-bottom: 2px solid white;
  align-items: center;
}

.flexIcon {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.polygonName {
  font-size: 30px;
  color: white;
  font-weight: bold;
  width: 100%;
}
.icons {
  max-height: 70%;
  margin-left: 50px;
}

.flexItem:hover {
  background: rgba(124, 124, 124, 0.3);
  opacity: 100%;
  cursor: pointer;
}

/* Partie Modal Form Update & Delete*/

.modalFormContainer {
  margin: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 900px;
  background-color: rgb(68, 68, 68);
  border-radius: 20px;
  border: 2px solid white;
}

.exitModal {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.exitButton {
  border: white 1px solid;
  border-radius: 30px;
  font-size: 40px;
  width: 40px;
}

.modalUpdateForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalUpdateTitle {
  font-size: 35px;
  color: white;
  padding: 10px;
  border: 1px solid white;
  border-radius: 10px;
  text-align: center;
  width: 50%;
}

.modalTextArea {
  border-radius: 10px;
  height: 100%;
  width: 50%;
  font-size: 50px;
  min-height: 50px;
  margin-top: 15px;
}

.modalSubmit {
  border: 1px solid white;
  color: white;
  font-size: 50px;
  background-color: rgb(52, 52, 52);
  border-radius: 10px;
  margin: 30px;
  height: 100%;
  width: 50%;
}

.modalSubmit:hover {
  cursor: pointer;
  background-color: rgb(87, 87, 87);
}

.modalDeleteForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexBool {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-evenly;
}

.modalDeleteBtn {
  margin: 15px;
  border: 1px solid white;
  background-color: rgb(52, 52, 52);
  border-radius: 10px;
  color: white;
  font-size: 50px;
}

.modalDeleteBtn:hover {
  cursor: pointer;
  background-color: rgb(87, 87, 87);
}

.emptyArrayList {
  color: white;
  border: 1px solid white;
  font-size: 4vw;
  text-align: center;
  width: 70%;
  background: rgba(124, 124, 124, 0.3);
  border-radius: 10px;
}
@media screen and (max-width: 915px) {
  .pageTitle {
    color: white;
    font-size: 10vw;
    text-align: center;
  }
  .searchInput {
    border-radius: 10px;
    height: 5vh;
    width: 100%;
    font-size: 6vw;
    min-height: 50px;
  }

  .thmTitle{
    font-size: 5vw;
  }
  .flexTitles {
    font-size: 4vw;
  }
  .flexItem {
    height: 12vh;
    min-height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 85%;
    border-bottom: 2px solid white;
    align-items: center;
  }

  .polygonName {
    font-size: 6vw;
    color: white;
    font-weight: bold;
    overflow-x: hidden;
    width: 100%;
  }

  .icons {
    max-height: 50%;
    margin-left: 15px;
  }
  /* Responsive modal */

  .modalFormContainer {
    width: 90%;
  }

  .modalUpdateTitle {
    width: 90%;
  }
  .modalTextArea {
    font-size: 7vw;
    width: 90%;
  }

  .modalSubmit {
    border: 1px solid white;
    color: white;
    height: 100%;
    font-size: 10vw;
    width: 90%;
    background-color: rgb(52, 52, 52);
  }
}
