.Indice {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 50vh;
  align-items: center;
}

.boxImg {
  width: 100%;
  position: relative;
}

.barometre {
  width: 90%;
  object-fit: contain;
  z-index: 5;
}

.aguille {
  position: absolute;
  bottom: 5%;
  left: 46%;
  z-index: 10;
  height: 80%;
  transform-origin: center bottom;
}

.dayName {
  font-size: 6vw;
  border: 2px solid rgb(213, 70, 45);
  border-radius: 10px;
  background-color: white;
  padding: 5px;
}

.dayIri {
  margin-top: 30px;
  color: white;
  font-size: 40px;
  margin-bottom: 0;
  padding: 0 25px 0 25px;
  border-radius: 25px;
}

.colorGreen {
  background-color: rgb(21, 148, 21);
}

.colorOrange {
  background-color: rgb(214, 156, 50);
}

.colorRed {
  background-color: rgb(213, 70, 45);
}

.dayDate {
  margin-top: 20px;
}

@media screen and (min-width: 400px) {
  .barometre {
    width: 400px;
  }

  .aguille {
    position: absolute;
    bottom: 5%;
    left: 44%;
    z-index: 10;
    height: 159px;
    transform-origin: center bottom;
  }

  .dayName {
    font-size: 20px;
  }
}
@media screen and (min-width: 850px) {
  .dayName {
    font-size: 35px;
  }

  .barometre {
    width: 500px;
  }
  .aguille {
    position: absolute;
    bottom: 5%;
    left: 46%;
    z-index: 10;
    height: 85%;
    transform-origin: center bottom;
  }
  .dayIri {
  }
  .dayDate {
  }
}
