/* Police d'écriture */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500&display=swap');

/* Image background */

/* body {
  background-image: url(../../../img/trianglify-lowres_1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
} */

/* Titre 'Connection' */

.connection {
  font-family: 'Quicksand', sans-serif;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 50px;
  font-size: 25px;
  color: #f1f1f1;
}

.inscription {
  font-family: 'Quicksand', sans-serif;
  color: #f1f1f1;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 50px;
  font-size: 25px;
}

/* Formulaire  */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  color: white;
}

/* Les titres des input  */

.lastnameP {
  font-family: 'Quicksand', sans-serif;
  align-content: left;
  color: #f1f1f1;
}

.firstnameP {
  font-family: 'Quicksand', sans-serif;
  align-content: left;
  color: #f1f1f1;
}

.emailP {
  font-family: 'Quicksand', sans-serif;
  color: #f1f1f1;
}

.passwordP {
  font-family: 'Quicksand', sans-serif;
  color: #f1f1f1;
}

/* input nom d'utilisateur */

.name {
  font-family: 'Quicksand', sans-serif;
  margin-bottom: 10px;
  height: 40px;
  width: 300px;
  font-size: large;
  border-radius: 10px;
  opacity: 0.8;
  border: none;
  background-color: #f1f1f1;
}

/* input mot de passe */

.password {
  font-family: 'Quicksand', sans-serif;
  margin-bottom: 10px;
  height: 40px;
  width: 300px;
  font-size: large;
  border-radius: 10px;
  opacity: 0.8;
  border: none;
  background-color: #f1f1f1;
  text-decoration: none;
  color: black;
}

#loginBtn {
  display: none;
}

/* Les boutons Sign Up & Login  */

.signUpLogin {
  font-family: 'Quicksand', sans-serif;
  display: flex;
  justify-content: center;
}

/* boutons Connection */

.login {
  font-family: 'Quicksand', sans-serif;
  font-size: large;
  margin-left: 15px;
  margin-bottom: 20px;
  width: 150px;
  height: 25px;
  border-radius: 30px;
  border: none;
  background-color: #f1f1f1f1;
  cursor: pointer;
}

/* boutons inscriptions */

.signUp {
  font-family: 'Quicksand', sans-serif;
  font-size: large;
  margin-left: 15px;
  margin-bottom: 20px;
  width: 150px;
  height: 25px;
  border-radius: 30px;
  border: none;
  background-color: #f1f1f1f1;
  cursor: pointer;
  text-decoration: none;
  color: black;
  text-align: center;
}

/* texte Mot de passe oublié */

.forgotPassword {
  display: block;
  font-family: 'Quicksand', sans-serif;
  text-align: center;
  font-size: large;
  color: #f1f1f1;
}
