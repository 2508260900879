.white {
  color: white;
}
.containerForecast {
  width: 100%;
  border-radius: 20px 20px 0 0;
  background: rgba(63, 63, 63, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 44vh;
}

.titleOption {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: 2px solid white;
}

.mapContainerFc {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fcMapItem {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 2px solid white;
}

.fcIri {
  font-size: 20px;
  font-weight: bold;
}
.fcDate {
  font-size: 20px;
  font-weight: bold;
}
.fcConseil{
  object-fit: contain;
  width: 7%;
}

@media screen and (min-width: 850px) {
  .containerForecast {
    height: 500px;
    width: 700px;
  }
  .mapContainerFc {
    overflow-y: scroll;
  }
}

@media screen and (max-height: 750px) {
  .containerForecast {
    height: 400px;
  }
}
