.titre {
  text-align: center;
  font-size: 7vw;
  margin-top: 30px;
  color: white;
  margin-bottom: 0;
}

.fire {
  color: #eb3e44;
}

.position_question {
  display: flex;
  justify-content: flex-end;
}

.questionPoint {
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: right;
}

.container_accueil {
  display: flex;
  justify-content: space space-around;
  flex-direction: column;
  align-items: center;
}

.bigDescription {
  width: 50vw;
  text-align: center;
  color: white;
  font-size: 2vw;
  background-color: rgba(26, 26, 26, 0.2);
  border-radius: 15px;
  padding: 10px;
}

.buttonAccueil {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35vw;
  height: 10vh;
  min-height: 75px;
  border: 2px solid white;
  border-radius: 15px;
  background-color: transparent;
  margin-bottom: 2vh;
  color: white;
  font-size: 2vw;
  text-decoration: none;
}

.buttonAccueil:hover {
  cursor: pointer;
  overflow: hidden;
  background-color: white;
  color: rgb(61, 61, 61);
}

/* Titre du logo */

.titleLogo {
  color: white;
  margin-top: 20px;
}

/* Logo VisioCrop */

.logoVisioCrop {
  width: 150px;
  background-color: white;
  border-radius: 15px;
}

@media screen and (max-width: 700px) {
  .titre {
    text-align: center;
    font-size: 15vw;
    margin-top: 60px;
    color: white;
  }

  .buttonAccueil {
    width: 60vw;
    height: 10vh;
    border: 2px solid white;
    border-radius: 15px;
    background-color: transparent;
    margin-bottom: 2vh;
    color: white;
    font-size: 6vw;
  }

  .bigDescription {
    width: 75vw;
    text-align: center;
    color: white;
    font-size: 3vw;
  }
}
