.containerForecastHome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
}
.forecast {
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  text-align: center;
}
/* .containerApp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

.indice {
  border: 1px solid red;
}

@media screen and (min-width: 850px) {
  .containerForecastHome {
    flex-direction: row;
    justify-content: space-evenly;
    height: 100vh;
    align-items: flex-start;
  }
  /* .containerApp {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .forecast {
    height: 400px;
    width: 400px;
    margin: auto;
  }
  .indice {
    height: 400px;
    width: 400px;
    position: relative;
    float: left;
  }
  .tableau {
    height: 400px;
    width: 400px;
    position: relative;
    float: left;
  } */
}

@media screen and (min-height: 501px) {
  .containerForecastHome {
    align-items: center;
    margin-top: 50px;

  }
}
