.ResetContainer {
  font-family: 'Quicksand', sans-serif;
  display: flex;
  margin-top: 111px;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 3vh;
  text-align: center;
}

.button {
  font-size: large;
  margin-left: 15px;
  margin-bottom: 20px;
  width: 150px;
  height: 25px;
  border-radius: 30px;
  border: none;
  background-color: #f1f1f1f1;
  cursor: pointer;
  text-decoration: none;
  color: black;
  text-align: center;
}

.nameLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.name {
  margin-top: 10px;
}
