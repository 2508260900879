.appMap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 35px;
  width: 100%;
  color: white;
  align-items: center;
}
.titlePage {
  padding-top: 50px;
  text-align: center;
}
.saveModal {
  border: 1px solid white;
  color: white;
  text-align: center;
  width: 100%;
  background: rgba(124, 124, 124, 0.3);
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.map-container {
  height: 40vh;
  width: 100%;
}

.formMapContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 30vh;
}

.buttonContainer {
  display: flex;
  width: 100%;
}

.mapButton {
  background-color: rgb(54, 54, 54);
  width: 100%;
  height: 40px;
  font-size: 30px;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
}

.mapButton:hover {
  background-color: rgb(83, 83, 83);
}

.namePosition {
  width: 70%;
  height: 40px;
  font-size: 20px;
  border-radius: 8px;
}

.submitButton {
  width: 70%;
  height: 40px;
  font-size: 20px;
  border-radius: 8px;
}
.disableButton {
  width: 70%;
  height: 40px;
  font-size: 20px;
  border-radius: 8px;
  background-color: rgb(106, 110, 103);
  color: white;
}
.enableButton {
  width: 70%;
  height: 40px;
  font-size: 20px;
  border-radius: 8px;
  background-color: rgb(162, 193, 139);
  color: white;
}

@media screen and (min-width: 700px) {
  .appMap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 35px;
    color: white;
    width: 100vw;
    height: 100vh;
    align-items: center;
  }
  /* .titlePage {
   top: 0px; 
  } */
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }

  .mapAndButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
  }

  .map-container {
    border-radius: 10px;
    height: 50vh;
  }

  .formMapContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 40%;
    height: 60vh;
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    width: 70%;
  }

  .mapButton {
    background-color: rgb(54, 54, 54);
    height: 40px;
    font-size: 30px;
    color: white;
    border: 2px solid white;
    width: 40%;
    cursor: pointer;
  }

  .namePosition {
    width: 70%;
    height: 40px;
    font-size: 20px;
    border-radius: 8px;
  }

  .submitButton {
    width: 70%;
    height: 40px;
    font-size: 20px;
    border-radius: 8px;
    cursor: pointer;
  }
  .disableButton {
    width: 70%;
    height: 40px;
    font-size: 20px;
    border-radius: 8px;
    background-color: rgb(106, 110, 103);
    color: white;
  }
  .enableButton {
    width: 70%;
    height: 40px;
    font-size: 20px;
    border-radius: 8px;
    cursor: pointer;
    background-color: rgb(162, 193, 139);
    color: white;
  }
}
